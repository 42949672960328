import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import {postRequest} from './utils/api'
import {putRequest} from './utils/api'
import {getRequest} from './utils/api'
import { deleteRequest } from './utils/api'
import store from './store/index'
import { initMenu } from './utils/menus'
import 'font-awesome/css/font-awesome.css'
import { downloadRequest } from './utils/download'

Vue.config.productionTip = false
Vue.use(ElementUI,{size:'small'})
// 以插件形式使用请求
Vue.prototype.postRequest = postRequest;
Vue.prototype.putRequest = putRequest;
Vue.prototype.getRequest = getRequest;
Vue.prototype.deleteRequest = deleteRequest;
Vue.prototype.downloadRequest = downloadRequest;

router.beforeEach((to, from, next) => {
  if (window.sessionStorage.getItem('tokenStr')) {
    initMenu(router, store);
    if (!window.sessionStorage.getItem('user')) {
      return getRequest("/admin/info").then(res => {
        if (res) {
          // 存入用户信息
          window.sessionStorage.setItem('user', JSON.stringify(res));
          store.commit('INIT_ADMIN',res);
          next();
        }
      })
    }
    next();
  } else {
    if (to.path == '/') {
      next();
    } else {
      next('/?redirect='+to.path);
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
