import axios from "axios";
import { Message } from 'element-ui';
import router from '../router'

// 配置请求拦截器
axios.interceptors.request.use(config=>{
    // 如果存在token，请求携带token
    if(window.sessionStorage.getItem('tokenStr')){
        config.headers['Authorization'] = window.sessionStorage.getItem('tokenStr');
    }
    return config;
}, error=>{
    console.log(error);
})


// 配置相应拦截器，显示相应信息
axios.interceptors.response.use(success=>{
    // 业务逻辑错误
    if(success.status && success.status == 200){
        if (success.data.code == 500 || success.data.code == 401 || success.data.code == 403) {
            Message.error({message:success.data.message});
        }
        else if(success.data.message){
            Message.success({message:success.data.message});
        }
    }
    return success.data;
},error=>{   
    if(error.status.code == 504 || error.status.code == 404){
        Message.error({message:'访问服务器发生错误'});
    }else if(error.status.code == 403){
        Message.error({message:'权限不足，请联系管理员'});
    }else if(error.status.code == 401){
        Message.error({message:'账户尚未登录，请登录'});
        router.replace('/')
    }else{
        if(error.response.data.message){
            Message.error({message:error.response.data.message})
        }else{          
            Message.error({message:'未知错误'})
        }
    }
    return;
});

let baseUrl = '/yeb';

// 传送json格式的post请求
export const postRequest = (url, params)=>{
    return axios({
        method:'post',
        url:`${baseUrl}${url}`,
        data:params
    })
}
// 传送json格式的put请求
export const putRequest = (url, params)=>{
    return axios({
        method:'put',
        url:`${baseUrl}${url}`,
        data:params
    })
}
// 传送json格式的get请求
export const getRequest = (url, params)=>{
    return axios({
        method:'get',
        url:`${baseUrl}${url}`,
        data:params
    })
}
// 传送json格式的delete请求
export const deleteRequest = (url, params)=>{
    return axios({
        method:'delete',
        url:`${baseUrl}${url}`,
        data:params
    })
}