<template lang="">
  <div>
    <el-form
      :rules="rules"
      ref="form"
      :model="loginForm"
      class="loginContainer"
    >
      <h3 class="loginTitle">系统登录</h3>
      <el-form-item prop="username">
        <el-input
          type="text"
          auto-complete="false"
          v-model="loginForm.username"
          placeholder="请输入用户名"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          type="password"
          auto-complete="false"
          v-model="loginForm.password"
          placeholder="请输入密码"
        ></el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-input
          type="text"
          auto-complete="false"
          v-model="loginForm.code"
          placeholder="点击图片更换验证码"
          style="width: 245px; margin-right: 5px;"
        ></el-input>
        <img :src="captchaUrl" @click="updateCaptcha"/>
      </el-form-item>
      <el-checkbox v-model="checked" class="loginRemeber">记住我</el-checkbox>
      <el-button type="primary" style="width: 100%" @click="submitLogin">登录</el-button>
    </el-form>
  </div>
</template>
<script>
export default {
  name: "Login",
  data() {
    return {
      captchaUrl: "/yeb/captcha?time=" + new Date(),
      loginForm: {
        username: "admin",
        password: "123456",
        code: "",
      },
      checked: true,
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }]
      },
    };
  },
  methods: {
    updateCaptcha() {
      this.captchaUrl = "/yeb/captcha?time=" + new Date();
    },
    submitLogin() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: "正在登录....",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.8)",
          });
          this.postRequest("/login", this.loginForm).then((res) => {
            loading.close();
            if (res) {
              if(res.code == 500){
                this.updateCaptcha();
              }else if(res.code == 200){
                // 存储用户token
                const tokenStr = res.data.tokenHead + res.data.token;
                window.sessionStorage.setItem("tokenStr", tokenStr);
                // 页面跳转
                // this.$router.replace("/home");
                let path = this.$route.query.redirect;
                let judge = (path=='/'||path==undefined);
                this.$router.replace(judge?'/home':path).catch(error=>{
                  this.$router.push('/home');
                })
              }
            }
          });
          setInterval(()=>{
            loading.close();
          }, 5000)
        } else {
          this.$message.error("请输入所有字段");
          return false;
        }
      });
    },
  },
};
</script>
<style>
.loginContainer {
  border-radius: 15px;
  background-clip: padding-box;
  margin: 180px auto;
  width: 350px;
  padding: 15px 35px 15px 35px;
  background-color: #fff;
  border: 1px solid #eaaaaa;
  box-shadow: 0 0 25px #cac6c6;
}

.loginTitle {
  margin: 0px auto 40px auto;
  text-align: center;
}

.loginRemeber {
  text-align: left;
  margin: 0px 0px 15px 0px;
}

.el-form-item__content{
    display: flex;
    align-items: center;
}
</style>
