<template>
    <div>
        <el-container>
            <el-header class="homeHeader">
                <div class="title">
                    云E办
                </div>
                <div>
                    <el-button 
                        icon="el-icon-bell" 
                        type="text" 
                        size="normal"
                        style="color:black;margin-right:8px"
                        @click="goChat"></el-button>
                    <el-dropdown class="userInfo" @command="commonHandler">
                        <span class="el-dropdown-link">
                            {{user.name}}<i><img :src="user.userFace"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="userInfo">个人中心</el-dropdown-item>
                            <el-dropdown-item command="settings">设置</el-dropdown-item>
                            <el-dropdown-item divided command="logout">注销登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </el-header>
                <el-container>
                    <el-aside width="200px">
                        <el-menu router unique-opened>
                            <template v-for="(item,index) in routes">
                                <el-submenu :index="index+''" :key="index" v-if="!item.hidden">
                                    <template slot="title">
                                        <i :class="item.iconCls" style="color:#1accff;margin-right:5px"></i>
                                        <span>{{item.name}}</span>
                                    </template>                                                                     
                                    <el-menu-item 
                                                :index="children.path" 
                                                v-for="(children,indexj) in item.children" 
                                                :key="indexj">
                                        {{children.name}}
                                    </el-menu-item>
                                </el-submenu>
                            </template>
                        </el-menu>
                    </el-aside>
                    <el-main>
                        <el-breadcrumb separator-class="el-icon-arrow-right" 
                                       v-if="this.$router.currentRoute.path!='/home'">
                            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                            <el-breadcrumb-item>{{this.$router.currentRoute.name}}</el-breadcrumb-item>
                        </el-breadcrumb>
                        <div class="homeWelcome" v-if="this.$router.currentRoute.path=='/home'">
                            欢迎来到云E办
                        </div>
                        <router-view class="homeRouterView"/>
                    </el-main>
                </el-container>
        </el-container>
    </div>
</template>

<script>

export default({
    name: "Home",
    data() {
        return {
            userFace:""
        }
    },
    computed:{
        routes(){
            return this.$store.state.routes;
        },
        user(){
            return this.$store.state.currentAdmin;
        }
    },
    methods: {
        goChat(){
            this.$router.push('/chat');
        },
        commonHandler(command){
            if(command == 'logout'){
                this.$confirm('此操作将注销登录, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.postRequest("/logout");
                    window.sessionStorage.removeItem('tokenStr');
                    window.sessionStorage.removeItem('user');
                    // 清空vuex的菜单信息
                    this.$store.commit("initRoutes",[]);
                    this.$router.replace('/');
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });          
                });
            }
            if(command == 'userInfo'){
                this.$router.push('/userInfo');
            }
        }
    },
})
</script>

<style scoped>
    .homeHeader{
        background-color: #409eff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        box-sizing: border-box;
    }
    .homeHeader .title{
        font-size: 30px;
        font-family: Arial, Helvetica, sans-serif;
        color: white;
    }
    .homeHeader .userInfo{
        cursor: pointer;
    }
    .el-dropdown-link img{
        width: 48px;
        height: 48px;
        border-radius: 24px;
        margin-left: 8px;
    }
    .homeWelcome{
        text-align: center;
        font-size: 30px;
        font-family: Arial, Helvetica, sans-serif;
        color: #409eff;
        padding-top: 58px;
    }
    .homeRouterView{
        margin-top: 22px;
    }
</style>
