<template>
  <div class="AdminInfo">
    <el-card class="box-card">
        <div slot="header" class="clearfix">
            <span>{{admin.name}}</span>
        </div>
        <div>
            <div class="adminImg">
                <img style="height:100px;width:100px;border-radius: 50px;" title="点击修改用户头像" :src="admin.userFace" alt="">
            </div>
            <div style="margin-top:8px">电话号码:<el-tag class="tag">{{admin.telephone}}</el-tag></div>
            <div style="margin-top:8px">手机号码:<el-tag class="tag">{{admin.phone}}</el-tag></div>
            <div style="margin-top:8px">居住地址:<el-tag class="tag">{{admin.address}}</el-tag></div>
            <div style="margin-top:8px;margin-left:4px">用户标签:
                <el-tag type="success" v-for="(r,index) in admin.roles" :key="index" class="tag">{{r.nameZh}}</el-tag>                
            </div>
            <div class="changeBtns">
                <el-button type="primary" @click="showUpdateAdminInfoView">修改信息</el-button>
                <el-button type="danger" @click="showUpdatePasswordView">修改密码</el-button>
            </div>
        </div>
    </el-card>
    <el-dialog
        title="编辑用户信息"
        :visible.sync="dialogVisible"
        width="30%">
        <div>
            <table>
                <tr>
                    <td>用户昵称:</td>
                    <td><el-input v-model="adminCopy.name"></el-input></td>
                </tr>
                <tr>
                    <td>电话号码:</td>
                    <td><el-input v-model="adminCopy.telephone"></el-input></td>
                </tr>
                <tr>
                    <td>手机号码:</td>
                    <td><el-input v-model="adminCopy.phone"></el-input></td>
                </tr>
                <tr>
                    <td>居住地址:</td>
                    <td><el-input v-model="adminCopy.address"></el-input></td>
                </tr>
            </table>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="updateAdminInfo">确 定</el-button>
        </span>
    </el-dialog>
    <el-dialog
        title="修改密码"
        :visible.sync="passworDialogVisible"
        width="30%">
        <div>
            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="请输入旧密码" prop="oldPass">
                    <el-input type="password" v-model="ruleForm.oldPass" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="请输入新密码" prop="pass">
                    <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="确认新密码" prop="checkPass">
                    <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
                </el-form-item>                
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'AdminInfo',
  data () {
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.ruleForm.checkPass !== '') {
            this.$refs.ruleForm.validateField('checkPass');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.pass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
    return {
        admin:{},
        adminCopy:{},
        dialogVisible:false,
        passworDialogVisible:false,
        ruleForm: {
          pass: '',
          checkPass: '',
          oldPass: ''
        },
        rules: {
          pass: [
            { validator: validatePass, trigger: 'blur' }
          ],
          checkPass: [
            { validator: validatePass2, trigger: 'blur' }
          ],
          oldPass: [
            { validator: validatePass, trigger: 'blur' }
          ]
        }
    };
  },
  mounted(){
      this.initAdmin();
  },
  methods: {
      showUpdatePasswordView(){
          this.passworDialogVisible = true;
      },
      showUpdateAdminInfoView(){
          this.dialogVisible = true;
          this.adminCopy = Object.assign({},this.admin);
      },
      initAdmin(){
          this.getRequest('/admin/info').then(res=>{
              if(res){
                  this.admin = res;
                  window.sessionStorage.setItem('user',JSON.stringify(res));
                  this.$store.commit('INIT_ADMIN',res);
              }
          });
      },
      updateAdminInfo(){
          this.putRequest('/admin/info',this.adminCopy).then(res=>{
              if(res){
                  this.dialogVisible = false;
                  this.initAdmin();
              }
          });
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
            if (valid) {
                this.ruleForm.adminId = this.admin.id;
                this.putRequest('/admin/pass',this.ruleForm).then(res=>{
                    if(res){
                        // 更新密码成功后退出登录
                        this.postRequest('/logout');
                        window.sessionStorage.removeItem('user');
                        window.sessionStorage.removeItem('tokenStr');
                        this.$store.commit("initRoutes",[]);
                        this.$router.replace('/');
                    }
                });
            } else {
                console.log('error submit!!');
                return false;
            }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
}
</script>

<style scoped>
    .box-card{
        width: 400px;
    }
    .adminImg{
        display: flex;
        justify-content: center;
    }
    .changeBtns{
        display: flex;
        justify-content: space-around;
        margin-top: 10px;
    }
    .tag{
        margin-left: 4px;
    }
</style>